import type { SearchEngineConfig } from "@uxu/utils/common/types";
import type { SearchSuggestionContentDetails } from '@uxu/design-system/components/organisms/searchEngine/searchEngineInModal/types';


export const searchEngineConfig: SearchEngineConfig<Array<SearchSuggestionContentDetails>> = {
    searchClientData: {
        indexName: 'article',
        api: {
            url: process.env.NEXT_PUBLIC_LINK_API_SEARCH || "",
            auth: process.env.NEXT_SECRET_SEARCH_ENGINE_SECRET_KEY || "",
        }
    },
    defaultSugestions: []
};
