export function setCookie(name: string, value: string): string {
    const sessionDurationInDays = parseInt("30", 10);
    const date = new Date();
    date.setTime(date.getTime() + (sessionDurationInDays * 24 * 60 * 60 * 1000));

    const expires = `expires=${date.toUTCString()}`;

    const cookieString = `${name}=${value}; ${expires}; path=/; SameSite=Strict`;

    document.cookie = cookieString;

    return cookieString;
}
